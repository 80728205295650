import {useState, useEffect, useRef} from "react"
import { oLevelCourses } from "../../data"
import { getData } from "../../services/request"
import { formatToWordsDate, getAPIBaseURL } from "../../services/helpers"
import ReactToPrint from "react-to-print"
import SingleStudentPrintCopy from "../../components/SingleStudentPrintCopy"

const SingleStudent = ({student, thisUser, setPage, lastPage}) => {
    let componentRef = useRef()
    const [stateName, setStateName] = useState("")
    const [imageURL, setImageURL] = useState("")
    // const [localGovernmentName, setLocalGovernmentName] = useState("")
    const [oLevelCombinationName, setOLevelCombinationName] = useState("")
    const [aLevelCombinationName, setALevelCombinationName] = useState("")
    const [genError, setGenError] = useState("")
    // const imageURL3 = `https://res.cloudinary.com/dyx7ojhb9/image/upload/v1691510577/IJMB/students/${student?.centerNumber}/pictures/${student?.number}?q=${10}&timestamp=${new Date().getTime()}.jpg`
    useEffect(() => {
        // alert(JSON.stringify(student))
        //setImageURL(`https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${student?.centerNumber}/${student?.number}?q=${10}&timestamp=${new Date().getTime()}.jpg`)
        //setImageURL(`https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${student?.centerNumber}/${student?.number}.jpg`)
        
        if(!stateName){
            getStateName(student.address.state)
        }
        /* if(!localGovernmentName){
            getLocalGovernmentName(student.address.localGovernment)
        } */
        if(!oLevelCombinationName){
            getOLevelCombinationName(student.oLevelCode)
        }
        if(!aLevelCombinationName){
            getALevelCombinationName(student.aLevelCode)
        }
        
    }, [])

    

    const getStateName = async (code) => {
        const url = `${getAPIBaseURL()}/v1/state`
        let state = ""
        const params = { code }
        //var accessToken = localStorage.getItem('token');
        // const headers = { 'token': accessToken }
        const headers = {}
        const requestResult = await getData(url, headers, params)
        //alert('Code: ' + code)
        if(requestResult.error == ""){
            //alert('No error')
            //alert(requestResult.result.status)
            if(requestResult.result.status === 200){
                //alert("Successful: 200")
                //setNotification(true)
                //alert(JSON.stringify(requestResult.result.data.data))
                //setPage('Lit')
                //alert('State name: ' + requestResult.result.data.data.name)
                setStateName(requestResult.result.data.data.name)
                
            }else if(404){
                //alert('Not found')
                setGenError('Not found')
            }else{
                setGenError('something went wrong')
            }

        }else{
            //alert('Error from catch')
            setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
        }
        return state
    }

    /* const getLocalGovernmentName = async (code) => {
        const url = `${getAPIBaseURL()}/v1/localgovernment`
        let state = ""
        const params = { code }
        //var accessToken = localStorage.getItem('token');
        //const headers = { 'token': accessToken }
        const headers = {}
        const requestResult = await getData(url, headers, params)
        //alert('Code: ' + code)
        if(requestResult.error == ""){
            //alert('No error')
            //alert(requestResult.result.status)
            if(requestResult.result.status === 200){
                //alert("Successful: 200")
                //setNotification(true)
                //alert(JSON.stringify(requestResult.result.data.data))
                //setPage('Lit')
                //alert('Local Government name: ' + requestResult.result.data.data.name)
                setLocalGovernmentName(requestResult.result.data.data.name)
                
            }else if(404){
                //alert('Not found')
                setGenError('Not found')
            }else{
                setGenError('something went wrong')
            }

        }else{
            //alert('Error from catch')
            setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
        }
        return state
    }*/

    const getOLevelCombinationName = async (code) => {
        const url = `${getAPIBaseURL()}/v1/combination`
        let state = ""
        const params = { code }
        // var accessToken = localStorage.getItem('token');
        // const headers = { 'token': accessToken }
        const headers = {}
        const requestResult = await getData(url, headers, params)
        //alert('Code: ' + code)
        if(requestResult.error == ""){
            //alert('No error')
            //alert(requestResult.result.status)
            if(requestResult.result.status === 200){
                //alert("Successful: 200")
                //setNotification(true)
                //alert(JSON.stringify(requestResult.result.data.data))
                //setPage('Lit')
                let combination = requestResult.result.data.data
                combination = `${combination.subject1}, ${combination?.subject2 ? combination?.subject2 : ""}`
                //alert('Combination name: ' + combination)
                setOLevelCombinationName(combination)
                
            }else if(404){
                //alert('Not found')
                setGenError('Not found')
            }else{
                setGenError('something went wrong')
            }

        }else{
            //alert('Error from catch')
            setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
        }
        return state
    }

    const getALevelCombinationName = async (code) => {
        const url = `${getAPIBaseURL()}/v1/combination`
        let state = ""
        const params = { code }
        // var accessToken = localStorage.getItem('token');
        // const headers = { 'token': accessToken }
        const headers = {}
        const requestResult = await getData(url, headers, params)
        //alert('Code: ' + code)
        if(requestResult.error == ""){
            //alert('No error')
            //alert(requestResult.result.status)
            if(requestResult.result.status === 200){
                //alert("Successful: 200")
                //setNotification(true)
                //alert(JSON.stringify(requestResult.result.data.data))
                //setPage('Lit')
                let combination = requestResult.result.data.data
                combination = `${combination.subject1}, ${combination?.subject2}, ${combination.subject3}`
                //alert('Combination name: ' + combination)
                setALevelCombinationName(combination)
                
            }else if(404){
                //alert('Not found')
                setGenError('Not found')
            }else{
                setGenError('something went wrong')
            }

        }else{
            //alert('Error from catch')
            setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
        }
        return state
    }
    
    return(
        <>
        {/*<div class="bg-gray-100">
        <div class="w-full text-white bg-main-color">
                <div x-data="{ open: false }"
                    class="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
                    <div class="p-4 flex flex-row items-center justify-between">
                        <a href="#"
                            class="text-lg font-semibold tracking-widest uppercase rounded-lg focus:outline-none focus:shadow-outline">example
                            profile</a>
                        <button class="md:hidden rounded-lg focus:outline-none focus:shadow-outline">
                            <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
                                <path x-show="!open" fill-rule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                    clip-rule="evenodd"></path>
                                <path x-show="open" fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </button>
                        </div>
                    </div>
                    <nav class="{'flex': open, 'hidden': !open}" class="flex-col flex-grow pb-4 md:pb-0 hidden md:flex md:justify-end md:flex-row">
                        <div class="relative" x-data="{ open: false }">
                            <button
                                class="flex flex-row items-center space-x-2 w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent hover:bg-blue-800 md:w-auto md:inline md:mt-0 md:ml-4 hover:bg-gray-200 focus:bg-blue-800 focus:outline-none focus:shadow-outline">
                                <span>{`${student.firstName} ${student.middleName} ${student.surname}`}</span>
                                <img class="inline h-6 rounded-full"
                                    src="https://avatars2.githubusercontent.com/u/24622175?s=60&amp;v=4"/>
                                <svg fill="currentColor" viewBox="0 0 20 20"
                                    class="inline w-4 h-4 transition-transform duration-200 transform">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                            <div
                                class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
                                <div class="py-2 bg-white text-blue-800 text-sm rounded-sm border border-main-color shadow-sm">
                                    <a class="block px-4 py-2 mt-2 text-sm bg-white md:mt-0 focus:text-gray-900 hover:bg-indigo-100 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                        href="#">Settings</a>
                                    <a class="block px-4 py-2 mt-2 text-sm bg-white md:mt-0 focus:text-gray-900 hover:bg-indigo-100 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                        href="#">Help</a>
                                    <div class="border-b"></div>
                                    <a class="block px-4 py-2 mt-2 text-sm bg-white md:mt-0 focus:text-gray-900 hover:bg-indigo-100 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                        href="#">Logout</a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>*/}
            <div class="container mx-auto my-5 p-5">
            <div className=" mb-6">
                    <button onClick={() => {setPage(lastPage);}} type="button" class="text-white bg-gray-700 hover:bg-black focus:ring-4 focus:outline-none focus:ring-black font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <div class="md:flex no-wrap md:-mx-2 ">
                    <div class="w-full md:w-3/12 md:mx-2">
                        <div class="bg-white p-3 border-t-4 border-blue-400">
                           
                            <div class="image overflow-hidden">
                                <img class="h-auto w-96 mx-auto"
                                    // src={imageURL3}
                                    src={`https://res.cloudinary.com/dyx7ojhb9/image/upload/v${Date.parse(student?.lastImageUpdate)}/IJMB/students/${student?.centerNumber}/pictures/${student?.number}?q=${10}.jpg`}
                                    alt=""/>
                            </div>
                            <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{`${student.name.firstName} ${student.name.middleName} ${student.name.surname}`}</h1>
                            <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{`${student.number}`}</h1>
                           {/* <h3 class="text-gray-600 font-lg text-semibold leading-6">Student</h3>*/}
                            {/*<p class="text-sm text-gray-500 hover:text-gray-600 leading-6">Lorem ipsum dolor sit amet
                                consectetur adipisicing elit.
                                Reprehenderit, eligendi dolorum sequi illum qui unde aspernatur non deserunt
                            </p>*/}
                            <ul
                                class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-6 divide-y rounded shadow-sm">
                                {/*<li class="flex items-center py-3">
                                    <span>Status</span>
                                    {student.status ? 
                                    <span class="ml-auto"><span class="bg-green-500 py-1 px-2 rounded text-white text-sm">Active</span></span> 
                                    : 
                                    <span class="ml-auto"><span class="bg-red-500 py-1 px-2 rounded text-white text-sm">Inactive</span></span>
                                    }
                                </li>*/}
                                <li class="flex items-center py-3">
                                    <span>Registered</span>
                                    <span class="ml-auto">{formatToWordsDate(student.createdAt?.substring(0, 10))}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="my-4"></div>
                        {/*<div class="bg-white p-3 hover:shadow">
                            <div class="flex items-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
                                <span class="text-green-500">
                                    <svg class="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                </span>
                                <span>Similar Profiles</span>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="text-center my-2">
                                    <img class="h-16 w-16 rounded-full mx-auto"
                                        src="https://cdn.australianageingagenda.com.au/wp-content/uploads/2015/06/28085920/Phil-Beckett-2-e1435107243361.jpg"
                                        alt=""/>
                                    <a href="#" class="text-main-color">Kojstantin</a>
                                </div>
                                <div class="text-center my-2">
                                    <img class="h-16 w-16 rounded-full mx-auto"
                                        src="https://avatars2.githubusercontent.com/u/24622175?s=60&amp;v=4"
                                        alt=""/>
                                    <a href="#" class="text-main-color">James</a>
                                </div>
                                <div class="text-center my-2">
                                    <img class="h-16 w-16 rounded-full mx-auto"
                                        src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                                        alt=""/>
                                    <a href="#" class="text-main-color">Natie</a>
                                </div>
                                <div class="text-center my-2">
                                    <img class="h-16 w-16 rounded-full mx-auto"
                                        src="https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/f04b52da-12f2-449f-b90c-5e4d5e2b1469_361x361.png"
                                        alt=""/>
                                    <a href="#" class="text-main-color">Casey</a>
                                </div>
                            </div>
                        </div>*/}
                       
                    </div>
                    <div class="w-full md:w-9/12 mx-2 h-64">
           
                        <div class="bg-white p-3 shadow-sm rounded-sm">
                            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                <span class="text-black">
                                    <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                    </svg>
                                </span>
                                <span class="tracking-wide">General Information</span>
                            </div>
                            <div class="text-gray-700">
                                <div class="grid md:grid-cols-2 text-sm">
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">First Name</div>
                                        <div class="px-0 py-2">{student.name.firstName}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Contact No.</div>
                                        <div class="px-0 py-2">{student.phoneNumber}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Surname</div>
                                        <div class="px-0 py-2">{student.name.surname}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Email.</div>
                                        <div class="px-0 py-2">
                                            <a class="text-blue-800" href="mailto:jane@example.com">{student.email}</a>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Middle Name</div>
                                        <div class="px-0 py-2">{student.name.middleName}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">NIN Number</div>
                                        <div class="px-0 py-2">{student.nin}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Gender</div>
                                        <div class="px-0 py-2">{student.gender == 1 ? 'Male' : 'Female'}</div>
                                    </div>
                                    {/* <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Local Government</div>
                                        <div class="px-0 py-2">{`${localGovernmentName}`}</div>
                                        </div> */}
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Birthday</div>
                                        <div class="px-0 py-2">{formatToWordsDate(student?.dob)}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">State</div>
                                        <div class="px-0 py-2">{`${stateName}`}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Examination Year</div>
                                        <div class="px-0 py-2">{student.examYear}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">institution Number</div>
                                        <div class="px-0 py-2">{student.institutionNumber}</div>
                                    </div>
                                </div>
                            </div>
                            {/*<button
                                class="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">School Information</button>
                            */}    
                        </div>

                        <div class="my-4"></div>

                        <div class="bg-white p-3 shadow-sm rounded-sm">

                            <div class="grid grid-cols-2">
                                <div>
                                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                        <span clas="text-green-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                                <path fill="#fff"
                                                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                            </svg>
                                        
                                        </span>
                                        <span class="tracking-wide">Subject Combinations</span>
                                    </div>
                                    <ul class="list-inside space-y-2">
                                        {/*<li>
                                            <div class="text-teal-600">Institution Number</div>
                                            <div class="text-gray-500 text-xs">{student.institutionNumber}</div>
                                        </li>*/}
                                        <li>
                                            <div class="text-blue-500">A Level Combination</div>
                                            <div class="text-gray-500 text-xs">{aLevelCombinationName}</div>
                                        </li>
                                        <li>
                                            <div class="text-blue-500 mb-2">O Level Combination</div>
                                            <div className="flex space-x-4">
                                                    <div class="text-gray-500 text-xs">{oLevelCombinationName}</div>
                                            </div>
                                        </li>
                                        {/*<li>
                                            <div class="text-teal-600">Owner at Her Company Inc.</div>
                                            <div class="text-gray-500 text-xs">March 2020 - Now</div>
                                        </li>*/}
                                    </ul>
                                </div>
                                {/*<div>
                                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                        <span class="text-green-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                            </svg>
                                        </span>
                                        <span class="tracking-wide">Education</span>
                                    </div>
                                    <ul class="list-inside space-y-2">
                                        <li>
                                            <div class="text-teal-600">Masters Degree in Oxford</div>
                                            <div class="text-gray-500 text-xs">March 2020 - Now</div>
                                        </li>
                                        <li>
                                            <div class="text-teal-600">Bachelors Degreen in LPU</div>
                                            <div class="text-gray-500 text-xs">March 2020 - Now</div>
                                        </li>
                                    </ul>
                                </div>*/}
                            </div>
                         
                        </div>
                        <div className="flex justify-end mt-4">
                            <ReactToPrint trigger={() => <button onClick={ () => window.print()} type="submit" class="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-md px-4 py-2 text-center mb-4">print</button>} content={() => componentRef} />
                            
                           <div class='print-source'><SingleStudentPrintCopy student={student} stateName={stateName} /*localGovernmentName={localGovernmentName}*/ oLevelCombinationName={oLevelCombinationName} aLevelCombinationName={aLevelCombinationName} ref={(el) => (componentRef = el)} /></div>
                        </div>
                    </div>
                </div>
            </div>
     </>
    )
}

export default SingleStudent