import React from "react";
import { formatToWordsDate } from "../services/helpers";

class SingleStudentPrintCopy extends React.Component {
    
    render() {
      return (
        <>
        <div class="container p-5 bg-white">
                <div class="">
                    
                    <div class="w-full mx-2">
           
                        <div class="bg-white w-full p-3 shadow-sm rounded-sm">
                            <div class='flex flex-col items-center space-x-4 mb-8'>
                                <h1 class='font-bold text-lg'>INTERIM JOINT MATRICULATION BOARD (IJMB)</h1> 
                                <h1 class='font-bold text-lg'>AHMADU BELLO UNIVERSITY, ZARIA</h1>
                                <h1 class='font-bold text-lg'>{`CANDIDATE REGISTRATION DATA, ${this.props.student.examYear}`}</h1>
                            </div>
                            <div className="mb-8 px-4">
                                <div class="image overflow-hidden">
                                <img class="h-auto w-44"
                                    /*src={`https://res.cloudinary.com/devrljvrd/image/upload/v1691510577/IJMB/students/${this.props.student?.centerNumber}/${this.props.student?.number}?q=${10}&timestamp=${new Date().getTime()}.jpg`*/
                                    src={`https://res.cloudinary.com/dyx7ojhb9/image/upload/v${Date.parse(this.props.student?.lastImageUpdate)}/IJMB/students/${this.props.student.centerNumber}/pictures/${this.props.student.number}?q=${10}.jpg`}
                                    alt=""/>
                                </div>
                                <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{`${this.props.student.number}`}</h1>
                            </div>

                            <div class="flex items-center space-x-2 px-4 font-semibold text-gray-900 leading-8 mb-3">
                                <span class="text-blue-500">
                                    <svg class="h-5" xmlns="http://www.w3.org/2000/svg" color="black" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                    </svg>
                                </span>
                                <span class="tracking-wide">General Information</span>
                            </div>
                            
                                <div class="bg-white w-full text-sm">


                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">First Name</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.name.firstName}</div>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">Contact</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.phoneNumber}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class=" col-span-6 px-4 py-2  font-semibold">Surname</div>
                                            <div class=" col-span-6 px-0 py-2 ">{this.props.student.name.surname}</div>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <div class=" col-span-6 px-4 py-2 font-semibold">Email</div>
                                            <div class=" col-span-6 px-0 py-2 ">{this.props.student.email}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">Mid. Name</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.name.middleName}</div>
                                        </div>
                                        {/* <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">LGA</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.localGovernmentName}</div>
                                        </div> */}
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">Gender</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.gender == 1 ? 'Male' : 'Female'}</div>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">State</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.stateName}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">D.O.B</div>
                                            <div class="col-span-6 px-0 py-2 ">{formatToWordsDate(this.props.student.dob)}</div>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">Institution</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.institutionNumber}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">NIN</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.nin}</div>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">Center No.</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.student.centerNumber}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">O Level</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.oLevelCombinationName}</div>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <div class="col-span-6 px-4 py-2  font-semibold">A Level</div>
                                            <div class="col-span-6 px-0 py-2 ">{this.props.aLevelCombinationName}</div>
                                        </div>
                                    </div>
                                    




                                    {/*<div class="grid grid-cols-2">
                                        <div className="flex">
                                            <div class="px-4 py-2 bg-green-700 font-semibold">First Name</div>
                                            <div class="px-0 py-2 bg-yellow-800">{this.props.student.name.firstName}</div>
                                        </div>
                                        <div className="flex">
                                            <div class="px-4 py-2 bg-green-700 font-semibold">Surname</div>
                                            <div class="px-0 py-2 bg-yellow-800">{this.props.student.name.surname}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Email.</div>
                                        <div class="px-0 py-2">
                                            <a class="text-blue-800" href="mailto:jane@example.com">{this.props.student.email}</a>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Middle Name</div>
                                        <div class="px-0 py-2">{this.props.student.name.middleName}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">NIN Number</div>
                                        <div class="px-0 py-2">{this.props.student.nin}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Gender</div>
                                        <div class="px-0 py-2">{this.props.student.gender == 1 ? 'Male' : 'Female'}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Local Government</div>
                                        <div class="px-0 py-2">{`${this.props.localGovernmentName}`}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Birthday</div>
                                        <div class="px-0 py-2">{formatToWordsDate(this.props.student.dob)}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">State</div>
                                        <div class="px-0 py-2">{`${this.props.stateName}`}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Examination Year</div>
                                        <div class="px-0 py-2">{this.props.student.examYear}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">institution Number</div>
                                        <div class="px-0 py-2">{this.props.student.institutionNumber}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Center Number</div>
                                        <div class="px-0 py-2">{this.props.student.centerNumber}</div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 font-semibold">Subject Combinations</div>
                                        <div class="px-0 py-2">{this.props.aLevelCombinationName}</div>
                                    </div>*/}

                                </div>
                            
                            {/*<button
                                class="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">School Information</button>
                            */}    
                        </div>

                        <div class="my-4"></div>

                        {/*<div class="bg-amber-500 w-full p-3 shadow-sm rounded-sm">

                            <div class="w-full">
                                <div className="bg-green-500 w-full">
                                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                        <span clas="text-blue-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                                <path fill="#fff"
                                                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                            </svg>
                                        
                                        </span>
                                        <span class="tracking-wide">Subject Combinations</span>
                                    </div>
                                    <ul class="w-full flex flex-col space-x-2 bg-blue-500">
                                        {/*<li>
                                            <div class="text-teal-600">Institution Number</div>
                                            <div class="text-gray-500 text-xs">{student.institutionNumber}</div>
                                        </li>
                                        <li className="w-full flex space-x-2 bg-yellow-400">
                                            <div class="text-blue-600">A Level</div>
                                            <div class="text-gray-500 text-xs">{this.props.aLevelCombinationName}</div>
                                        </li>
                                        <li className="w-full flex space-x-2 bg-red-500">
                                            <div class="text-blue-500 mb-2">O Level</div>
                                            <div className="flex space-x-4">
                                                    <div class="text-gray-500 text-xs">{this.props.oLevelCombinationName}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="text-teal-600">Owner at Her Company Inc.</div>
                                            <div class="text-gray-500 text-xs">March 2020 - Now</div>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                        <span class="text-green-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                            </svg>
                                        </span>
                                        <span class="tracking-wide">Education</span>
                                    </div>
                                    <ul class="list-inside space-y-2">
                                        <li>
                                            <div class="text-teal-600">Masters Degree in Oxford</div>
                                            <div class="text-gray-500 text-xs">March 2020 - Now</div>
                                        </li>
                                        <li>
                                            <div class="text-teal-600">Bachelors Degreen in LPU</div>
                                            <div class="text-gray-500 text-xs">March 2020 - Now</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                         
                        </div>*/}
                    </div>
                </div>
            </div>
          </>  
      );
    }
  }

  export default SingleStudentPrintCopy
  